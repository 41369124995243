import request from "../utils/request";

export function get_coupon_list(data: JSON){
    return request({
        url: "/getCouponList",
        method: "post",
        data: data
    });
}

export function get_coupon_detail(data: JSON){
    return request({
        url: "/getCouponDetail",
        method: "post",
        data: data
    });
}

export function delete_coupon(data: JSON) {
    return request({
        url: "/deleteCoupon",
        method: "post",
        data: data
    });
}

export function save_coupon_detail(data: FormData){
    return request({
        url: "/saveCouponDetail",
        method: "post",
        data: data
    });
}

export function get_coupon_store_list(data: JSON){
    return request({
        url: "/getCouponStoreList",
        method: "post",
        data: data
    });
}
